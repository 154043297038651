import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Environment } from '@environment';
import { ChannelService } from '@usheru-hff/shared/utils-channel';
import { TitleService } from '@usheru-hff/shared/utils-title';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';
import { MetaTags } from '../entities/metatags.model';
import { ENVIRONMENT } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {
  // prettier-ignore
  constructor(
    private meta: Meta,
    @Inject(ENVIRONMENT) private env: Environment,
    private translate: TranslationsService,
    
    private titleService: TitleService,
    private channelService: ChannelService
  ) {
  }

  setMetaTags(metaTags?: MetaTags, values?: ReplacedValues): void {
    values = {
      channel: this.channelService.channel.name || '',
      siteName: this.env.siteName || '',
      ...values
    };
    //
    metaTags.title = this.titleService.setTitleExtended({
      title: metaTags.title,
      defaultTitle: metaTags?.defaultTitle,
      values: values as { [key: string]: string }
    });
    //
    this.titleService.setFavicon(metaTags?.favicon);
    //
    metaTags.description = this.translate.translate(metaTags?.description || '');
    for (const key in values) {
      metaTags.description = metaTags.description.replace(new RegExp(`_${key}_`, 'g'), values[key]);
    }
    // if image is not provided then use the default one from env or channel or the logo
    if (!metaTags.image) {
      metaTags.image =
        this.env.channelTilePhoto || this.channelService.channel.tilePhotoUrl || this.channelService.channel.logoUrl;
    }

    // Metatags titles should be the tab titles also therefore setting up tab

    this.setStandardTags();

    if (metaTags) {
      if (metaTags.title) {
        this.setTag('og:title', metaTags.title); // Facebook
        this.setTag('twitter:title', metaTags.title); // Twitter
      }

      if (metaTags.description) {
        this.setTag('description', metaTags.description);
        this.setTag('og:description', metaTags.description); // Facebook
        this.setTag('twitter:description', metaTags.description); // Twitter
      }

      if (metaTags.url) {
        this.setTag('og:url', metaTags.url); // Facebook
      }

      if (metaTags.image) {
        this.setTag('image', metaTags.image);
        this.setTag('og:image', metaTags.image);
        this.setTag(metaTags.image.indexOf('http:') === 0 ? 'og:image' : 'og:image:secure_url', metaTags.image); // Facebook
        this.setTag('twitter:image', metaTags.image); // Twitter
        this.setTag('twitter:imageUrl', metaTags.image); // Twitter
      }

      if (metaTags.video) {
        this.setTag('video', metaTags.video); // Facebook
        this.setTag('og:video', metaTags.video); // Facebook
        this.setTag('twitter:player:stream', metaTags.video); // Twitter
      }

      this.setTag('og:type', 'article'); // Facebook
      this.setTag('twitter:card', metaTags.image ? 'summary_large_image' : 'summary'); // Twitter
    }
  }

  private setStandardTags(): void {
    // todo: maybe create a service to provide these 3 settings based on env or channel
    this.setTag('og:site_name', this.channelService.channel.name);
    this.setTag('twitter:site', this.env?.twitter?.site || 'https://twitter.com/usheruHQ');
    this.setTag('twitter:creator', this.env?.twitter?.creator || 'https://twitter.com/usheruHQ');
  }

  private setTag(property: string, content: string): void {
    this.meta.updateTag({
      property: property,
      content: content
    });
    if (property == 'description') {
      this.meta.updateTag({
        name: property,
        content: content
      });
    }
  }

  resetMetaTags(): void {
    /**
     * TODO Should reset here the title and favicon to the default ones?
     */
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="twitter:title"');

    this.meta.removeTag('property="description"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="twitter:description"');

    this.meta.removeTag('property="og:url"');

    this.meta.removeTag('property="image"');
    this.meta.removeTag('property="og:image"');
    this.meta.removeTag('property="og:image:secure_url"');
    this.meta.removeTag('property="twitter:image"');
    this.meta.removeTag('property="twitter:imageUrl"');

    this.meta.removeTag('property="video"');
    this.meta.removeTag('property="og:video"');
    this.meta.removeTag('property="twitter:player:stream"');

    this.meta.removeTag('property="og:type"');
    this.meta.removeTag('property="twitter:card"');
  }
}

interface ReplacedValues {
  channel?: string;
  cinema?: string;
  movie?: string;
  director?: string;
  siteName?: string;
  tag?: string;
  type?: string;
  star?: string;
  cast?: string;
  newsTitle?: string;
  newsChunk?: string;
  city?: string;
}
